import React, { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";

const AddPromoCode = ({ onReload, email }) => {
  const [form, setForm] = useState({
    promoCode: "",
    discount: "",
    expirationDate: "",
    valid: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // Function to generate promo code
  const generatePromoCode = () => {
    const generatedCode = Math.random()
      .toString(36)
      .substring(2, 10)
      .toUpperCase();
    setForm({ ...form, promoCode: generatedCode });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Use the user-defined promo code or generate one if empty
    const code =
      form.promoCode.trim() !== ""
        ? form.promoCode
        : Math.random().toString(36).substring(2, 10).toUpperCase();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/promo-codes/create`,
        {
          ...form,
          code,
          email: email, // Use email prop instead of taking it from form
        }
      );

      console.log("Success response:", response.data);
      window.location.reload();

      // Reset form fields
      setForm({ promoCode: "", discount: "", expirationDate: "", valid: "" });

      // Trigger reload to update the list
      onReload();
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8"
    >
      <div className="flex items-center">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Promo Code
        </label>
      </div>
      <div className="flex items-center justify-between mb-4">
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
          name="promoCode"
          type="text"
          value={form.promoCode}
          onChange={handleChange}
          placeholder="Enter your promo code or generate random code"
          required
        />
        <button
          type="button"
          onClick={generatePromoCode}
          className="ml-2 bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold py-2 px-4 rounded"
        >
          Generate
        </button>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Discount (RM)
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
          name="discount"
          type="number"
          value={form.discount}
          onChange={handleChange}
          placeholder="Enter the discount amount"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Expiration Date
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
          name="expirationDate"
          type="date"
          value={form.expirationDate}
          onChange={handleChange}
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Valid Count
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
          name="valid"
          type="number"
          value={form.valid}
          onChange={handleChange}
          placeholder="Enter the number of times the promo code can be used"
          required
        />
      </div>

      <button
        className="bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded"
        type="submit"
      >
        Add Promo Code
      </button>
    </form>
  );
};

export default AddPromoCode;
