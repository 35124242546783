import React, { useEffect } from 'react';
import axios from 'axios';

const Auth = ({ setLogin }) => {
  const email = localStorage.getItem('userEmail');
  const token = localStorage.getItem('token');

  useEffect(() => {
    if(email) {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/sessions/check`, {
        email: email,
        token: token,
      })
        .then(response => {
          setLogin(true);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLogin(false);
        });
    } else {
      // User is signed out
      setLogin(false);
      console.log('User is signed out');
    }
  }, [setLogin]);

  return null; // or any UI you want to render for this component
};

export default Auth;
