import React, { useState } from "react";
import axios from "axios";

const EditPromoCode = ({ promo, onEditComplete, onReload, email }) => {
  const [form, setForm] = useState({ ...promo, email }); // Initialize email from props

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/promo-codes/${promo.id}`,
        form
      );

      console.log("Success response:", response.data); // Log success response

      onEditComplete(); // Close the form
      onReload(); // Reload promo codes list
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-8 pt-6 pb-8"
    >
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Discount (RM)
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
          name="discount"
          type="number"
          value={form.discount}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Expiration Date
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
          name="expirationDate"
          type="date"
          value={form.expirationDate}
          onChange={handleChange}
          required
        />
      </div>
      {/* <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Admin Email</label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                    name="email"
                    type="email"
                    value={form.email} // Use email from form
                    readOnly // Make this field readonly
                />
            </div> */}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Valid Count
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
          name="valid"
          type="number"
          value={form.valid}
          onChange={handleChange}
          required
        />
      </div>
      <button
        className="bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded"
        type="submit"
      >
        Save Changes
      </button>
      <button
        className="ml-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
        type="button"
        onClick={onEditComplete}
      >
        Cancel
      </button>
    </form>
  );
};

export default EditPromoCode;
