import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import axios from 'axios';

const OtpForm = ( { setShowOtp, email } ) => {
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [countdown, setCountdown] = useState();
  const navigate = useNavigate();

  const enableResend = () => {
    setButtonEnabled(false);
    setCountdown(30); // Reset countdown timer

    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000); // Update countdown every second

    // // Enable the button after countdown finishes
    setTimeout(() => {
      clearInterval(timer);
      setButtonEnabled(true);
    }, 30000);
  };
  
  const resend = () => {
    enableResend();
    // sendEmail(email, 2, { otp: passcode });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the data to the API
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/verify-otp`, {
        email,
        otp, // Send OTP to the server
      });

      setMessage(response.data.message);
      localStorage.setItem('userEmail', email);
      localStorage.setItem('token', response.data.token);
      window.location.reload();
    } catch (error) {
      // Handle error
      console.error('OTP verification failed:', error.response.data.message);
      setMessage(error.response.data.message)
    }
  };

  return (
    <div>
      <Navbar />
      <div className="min-h-screen flex md:items-center justify-center pt-20 bg-white md:bg-gray-100">
        <div className="bg-white p-6 rounded md:shadow-md w-full max-w-md ">
          <h2 className="text-2xl font-semibold mb-4">Sahkan email anda</h2>
          <p className="text-lg mb-4">Masukkan 6 digit kod OTP yang telah dihantar ke email {email}:</p>
          <button className="text-md text-button1 underline mb-4" onClick={() => setShowOtp(false)}>Nak ubah email? Klik sini</button>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">OTP:</label>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder='XXXXXX'
                className="w-full border rounded py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                required
              />
            </div>
            <p className='my-4 text-button1'>{message}</p>
            <div className='flex space-x-2'>
              <button
                type="submit"
                className="bg-button1 text-white py-2 px-4 rounded hover:bg-button1Hover focus:outline-none focus:ring focus:border-red-300"
              >
                Sahkan
              </button>
            </div>
          </form>
          <button
            className={`${buttonEnabled ? 'bg-button1 hover:bg-button1 focus:border-button1' : 'bg-gray-400 hover:bg-gray-500 focus:border-gray-300'} mt-4 text-white py-2 px-4 rounded focus:outline-none focus:ring`}
            onClick={resend} disabled={!buttonEnabled}
          >
            {buttonEnabled ? 'Resend OTP' : `Resend OTP (${countdown})`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OtpForm;
