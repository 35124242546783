import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { FaEnvelope, FaWhatsapp, FaSpinner } from 'react-icons/fa';

function Sales() {
  const [payments, setPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Loading state
  const paymentsPerPage = 20;

  useEffect(() => {
    // Fetch payments data from API
    setLoading(true); // Start loading
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/payments`)
      .then(response => {
        setPayments(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the payments data!", error);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  }, []);

  useEffect(() => {
    // Scroll to top when currentPage changes
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  const formatDateMalaysia = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).replace(',', '');
  };

  // Remove duplicates based on payment_id and sort by recent
  const uniquePayments = Array.from(
    new Map(payments.map((item) => [item.payment_id, item]))
      .values()
  ).sort((a, b) => new Date(b.paid_at) - new Date(a.paid_at));

  // Pagination calculations
  const indexOfLastPayment = currentPage * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentPayments = uniquePayments.slice(indexOfFirstPayment, indexOfLastPayment);
  const totalPages = Math.ceil(uniquePayments.length / paymentsPerPage);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <Helmet>
        <title>Sales | RSVPKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div>
        <h1 className="text-3xl font-bold mb-4">Sales</h1>
        <p>Welcome to the Sales!</p>

        <div className='mt-8'>
          <a
            href={`${process.env.REACT_APP_PAY_BASE_URL}/wp-admin/admin.php?page=wc-orders`}
            className='bg-button1 hover:bg-button1Hover px-3 py-2 rounded-md block w-2/3 text-center text-white'
          >
            Klik Sini Untuk Lihat Sales
          </a>
        </div>
      </div>
    </div>
  );
}

export default Sales;
