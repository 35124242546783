import React, { useState } from 'react';
import axios from 'axios';
import { FaAngleLeft } from 'react-icons/fa';

const AddItems = ({ setAddItem }) => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [link, setLink] = useState('');
  const [price, setPrice] = useState('');

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('image', image);
    formData.append('link', link);
    formData.append('price', price);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/add-item`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Item added:', response.data);
      window.location.reload();
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  return (
    <div className="">
      <div className='flex items-center'>
        <button onClick={() => setAddItem(false)}><FaAngleLeft className='text-4xl mr-4' /></button>
        {/* <button className='py-2'><FaAngleLeft className='text-4xl mr-4' /></button> */}
        <h1 className="text-3xl font-bold mb-4">Add Item</h1>
      </div>
      <form onSubmit={handleSubmit} className="max-w-3xl">

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
            placeholder="Enter item title"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Image</label>
          <input
            type="file"
            onChange={handleImageChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Link</label>
          <input
            type="url"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
            placeholder="Enter external URL"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Price</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-400"
            placeholder="Enter item price"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-button1 text-white font-medium py-2 px-4 rounded-md hover:bg-button1Hover transition-colors duration-200"
        >
          Add Item
        </button>
      </form>
    </div>
  );
};

export default AddItems;
